import { combineReducers } from 'redux';

import AuthReducer from './Auth';
import RegisterReducer from './Register';
import SnackbarReducer from './Snackbar';
import LabelingReducer from './Labeling';
import DatasetReducer from './Dataset';
import UploadReducer from './Upload';

const reducers = combineReducers({
  auth: AuthReducer,
  register: RegisterReducer,
  snackbar: SnackbarReducer,
  labeling: LabelingReducer,
  dataset: DatasetReducer,
  upload: UploadReducer
});

export type AppState = ReturnType<typeof reducers>;

export default reducers;
