import React, { useState, FormEvent } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Moment } from 'moment';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';

import { AppState } from '../../../reducers';
import { Profile } from '../../../models/Profile';
import RegisterProvider from '../../../providers/Register';
import TermsAndConditionsDialog from './TermsAndConditionsDialog';

interface Props {
  loading?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(10),
    display: 'flex',
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(4),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatarColumn: {
    paddingLeft: theme.spacing(3),
    flex: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    border: `2px solid ${theme.palette.primary.main}`,
    flex: 1,
  },
  headerColumn: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    flex: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    marginTop: -theme.spacing(3),
  },
  contentRow: {
    display: 'flex',
    flexDirection: 'row',
    '& > *:first-child': {
      marginRight: theme.spacing(1.5),
    },
    '& > *:last-child': {
      marginLeft: theme.spacing(1.5),
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  button: {
    flex: 0,
    flexBasis: theme.spacing(20),
    color: '#FFF',
  }
}));

const Register: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user, getAccessTokenSilently, logout } = useAuth0();

  const [fullName, setFullName] = useState<string|null>(user?.name || null);
  const [documentNumber, setDocumentNumber] = useState<string|null>(null);
  const [birthdate, setBirthdate] = useState<Moment|null>(null);
  const [addressStreet, setAddressStreet] = useState<string|null>(null);
  const [addressLocality, setAddressLocality] = useState<string|null>(null);
  const [addressDistrict, setAddressDistrict] = useState<string|null>(null);
  const [addressProvince, setAddressProvince] = useState<string|null>(null);
  const [postalCode, setPostalCode] = useState<string|null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string|null>(null);
  const [educationLevel, setEducationLevel] = useState<string|null>(null);
  const [job, setJob] = useState<string|null>(null);
  const [accepted, setAccepted] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleOpenDialog = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOpenDialog(true);
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (!fullName) return;
    if (!documentNumber) return;
    if (!birthdate) return;
    if (!addressStreet) return;
    if (!addressLocality) return;
    if (!addressDistrict) return;
    if (!addressProvince) return;
    if (!postalCode) return;
    if (!phoneNumber) return;
    if (!educationLevel) return;
    if (!job) return;

    const profile = {
      fullName,
      documentNumber,
      birthdate: birthdate.format('DD/MM/YYYY'),
      addressStreet,
      addressLocality,
      addressDistrict,
      addressProvince,
      postalCode,
      phoneNumber,
      educationLevel,
      job
    } as Profile;

    if (props.loading === false) {
      dispatch(RegisterProvider.createProfile(getAccessTokenSilently, logout, profile));
    }
  };

  return (
    <div className={classes.root}>
      <Container>
        <Grid container justify="center">
          <Grid item md={8} sm={12} xs={12}>
            <Typography align="center" variant="h4">
              Crear cuenta
            </Typography>
            <Paper square className={classes.paper}>
              <form onSubmit={handleSubmit}>
                <div className={classes.header}>
                  <div className={classes.avatarColumn}>
                    <Avatar
                      className={classes.avatar}
                      src={user!.picture}
                    />
                  </div>
                  <div className={classes.headerColumn}>
                    <TextField
                      size="small"
                      label="Nombre y apellido"
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                      required={true}
                      value={fullName}
                      onChange={props => setFullName(props.target.value)}
                    />
                    <TextField
                      size="small"
                      label="Email"
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                      required={true}
                      value={user?.email}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className={classes.content}>
                  <div className={classes.contentRow}>
                    <TextField
                      size="small"
                      label="DNI"
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                      required={true}
                      value={documentNumber}
                      onChange={props => setDocumentNumber(props.target.value)}
                    />
                    <KeyboardDatePicker
                      size="small"
                      label="Fecha de nacimiento"
                      margin="normal"
                      fullWidth={true}
                      required={true}
                      format="DD/MM/YYYY"
                      cancelLabel="Cerrar"
                      TextFieldComponent={ props =>
                        <TextField {...props} variant="outlined" />
                      }
                      value={birthdate}
                      onChange={setBirthdate}
                    />
                  </div>
                  <div className={classes.contentRow}>
                    <TextField
                      size="small"
                      label="Domicilio (calle y altura)"
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                      required={true}
                      value={addressStreet}
                      onChange={props => setAddressStreet(props.target.value)}
                    />
                    <TextField
                      size="small"
                      label="Localidad"
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                      required={true}
                      value={addressLocality}
                      onChange={props => setAddressLocality(props.target.value)}
                    />
                  </div>
                  <div className={classes.contentRow}>
                    <TextField
                      size="small"
                      label="Partido"
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                      required={true}
                      value={addressDistrict}
                      onChange={props => setAddressDistrict(props.target.value)}
                    />
                    <TextField
                      size="small"
                      label="Provincia"
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                      required={true}
                      value={addressProvince}
                      onChange={props => setAddressProvince(props.target.value)}
                    />
                  </div>
                  <div className={classes.contentRow}>
                    <TextField
                      size="small"
                      label="Código postal"
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                      required={true}
                      value={postalCode}
                      onChange={props => setPostalCode(props.target.value)}
                    />
                    <TextField
                      size="small"
                      label="Celular"
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                      required={true}
                      value={phoneNumber}
                      onChange={props => setPhoneNumber(props.target.value)}
                    />
                  </div>
                  <div className={classes.contentRow}>
                    <TextField
                      size="small"
                      label="Nivel máximo de educación alcanzado"
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                      required={true}
                      value={educationLevel}
                      onChange={props => setEducationLevel(props.target.value)}
                    />
                    <TextField
                      size="small"
                      label="Ocupación"
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                      required={true}
                      value={job}
                      onChange={props => setJob(props.target.value)}
                    />
                  </div>
                  <FormControlLabel
                    label={
                      <span>Acepto los <Link href="#" onClick={handleOpenDialog}>Términos y Condiciones</Link></span>
                    }
                    control={
                      <Checkbox
                        checked={accepted}
                        onChange={evt => setAccepted(evt.target.checked)}
                        color="primary"
                        required={true}
                      />
                    }
                  />
                </div>
                <div className={classes.footer}>
                  <Button
                    className={classes.button}
                    disabled={props.loading}
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                  >
                    Crear cuenta
                  </Button>
                </div>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <TermsAndConditionsDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  loading: state.register.loading
});

export default connect(mapStateToProps, null)(Register);
