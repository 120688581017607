import { Dispatch } from 'redux';
import { AppState } from '../reducers';
import { RegisterAction } from '../reducers/Register';
import SnackbarProvider from '../providers/Snackbar';
import AuthProvider from '../providers/Auth';
import { Profile } from '../models/Profile';

export interface RegisterProvider {
  createProfile: (getAccessToken: () => Promise<string>, logout: () => void, profile: Profile) => void;
}

const createProfile = (getAccessToken: () => Promise<string>, logout: () => void, profile: Profile) => {

  return async (dispatch: Dispatch<RegisterAction>, getState: () => AppState) => {

    dispatch({ type: 'REGISTER_LOADING' });

    try {

      const token = await getAccessToken();

      if (token) {

        const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${ token }`
          },
          body: JSON.stringify(profile)
        });

        const body = await response.json();

        if (response.status !== 200) throw new Error(body.message);
        
        dispatch({ type: 'REGISTER_SUCCESS' });

        dispatch(SnackbarProvider.showSuccessAlert('Cuenta creada correctamente') as any);

        dispatch(AuthProvider.setToken(body.token) as any);

      } else {

        dispatch({ type: 'REGISTER_FAILED' });

        dispatch(SnackbarProvider.showErrorAlert('Error al crear la cuenta') as any);

        logout();

      }

    } catch (error) {

      console.log(error.message);

      dispatch({ type: 'REGISTER_FAILED' });

      dispatch(SnackbarProvider.showErrorAlert('Error al crear la cuenta') as any);

    }

  }

}

const registerProvider: RegisterProvider = { createProfile };

export default registerProvider;