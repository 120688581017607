import { Dataset } from '../models/Sample';

export interface DatasetAction {
  type: 'DATASET_LIST_FETCH' | 'LOCATION_ADDED';
  cropTypes?: Dataset[];
  locations?: Dataset[];
  newLocation?: Dataset;
}

export interface DatasetState {
  cropTypes?: Dataset[];
  locations?: Dataset[];
  newLocation?: Dataset;
}

const initialState = {};

const DatasetReducer = (state: DatasetState = initialState, action: DatasetAction): DatasetState => {
  switch (action.type) {
    case 'DATASET_LIST_FETCH':
      return {
        cropTypes: action.cropTypes,
        locations: action.locations
      };
    case 'LOCATION_ADDED':
      return {
        ...state,
        locations: [
          ...(state.locations || []),
          action.newLocation!
        ],
        newLocation: action.newLocation!
      }
    default:
      return state;
  }
}

export default DatasetReducer;