import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import ImageIcon from '@material-ui/icons/Image';
import UploadIcon from '@material-ui/icons/AddPhotoAlternate';
import PendingIcon from '@material-ui/icons/QueryBuilder';
import UploadingIcon from '@material-ui/icons/CloudUpload';
import SentIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Cancel';
import AddLocationIcon from '@material-ui/icons/Add';

import { Dataset } from '../../models/Sample';
import { Job } from '../../reducers/Upload';

interface Props {
  open: boolean;
  jobs: Job[];
  cropTypes?: Dataset[];
  locations?: Dataset[];
  newLocation?: Dataset;
  onClose: () => void;
  onOpenLocation: () => void;
  onUpload: (files: File[], crop: Dataset, location: Dataset) => void;
}

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: '#FFF',
    backgroundColor: theme.palette.primary.light,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  content: {
    padding: theme.spacing(2),
  },
  list: {
    margin: -theme.spacing(1),
    marginTop: -theme.spacing(2),
    marginBottom: -theme.spacing(2),
  },
  dropzoneArea: {
    borderColor: 'rgba(0,0,0,.23)',
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  dropzoneIcon: {
    flex: 0,
    flexBasis: 64,
    width: 64,
    color: '#BBB',
    marginBottom: 8,
  },
  dropzoneTitle: {
    flex: 0,
    color: '#888',
    fontSize: 50,
    textAlign: 'center',
    fontWeight: 500,
    marginTop: -20,
  },
  dropzoneText: {
    flex: 0,
    color: '#888',
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 400,
  },
  dropzoneTextLight: {
    color: '#AAA',
    fontWeight: 300,
  },
  dropzoneFormRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  dropzoneIconButton: {
    flexBasis: theme.spacing(5),
    marginLeft: theme.spacing(2),
    borderColor: 'rgba(0,0,0,.23)',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  pendingIcon: {
    marginRight: theme.spacing(1),
    color: 'rgba(0,0,0,.23)',
  },
  uploadingIcon: {
    marginRight: theme.spacing(1),
    color: '#4caf50',
  },
  sentIcon: {
    marginRight: theme.spacing(1),
    color: '#4caf50',
  },
  errorIcon: {
    marginRight: theme.spacing(1),
    color: '#a00',
  },
}));

const UploadDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [cropType, setCropType] = useState<Dataset|null>(null);
  const [location, setLocation] = useState<Dataset|null>(null);
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({accept: 'image/*'});

  const handleSend = () => {
    if (cropType !== null && location !== null) {
      props.onUpload(acceptedFiles, cropType, location);
    }
  };

  const handleUpdateCropType = (id: string) => {
    setCropType((props.cropTypes || []).find(dataset => dataset.id === id) || null);
  };

  const handleUpdateLocation = (id: string) => {
    setLocation((props.locations || []).find(dataset => dataset.id === id) || null);
  };

  const getJobStatus = (job: Job): string => {
    if (job.sending) return 'Subiendo';
    if (job.sent) return 'Subido';
    if (job.error) return `Error: ${job.error}`;
    return 'Pendiente';
  };

  const getJobIcon = (job: Job) => {
    if (job.sending) return <UploadingIcon className={classes.uploadingIcon} />;
    if (job.sent) return <SentIcon className={classes.sentIcon} />;
    if (job.error) return <ErrorIcon className={classes.errorIcon} />;
    return <PendingIcon className={classes.pendingIcon} />;
  };

  useEffect(() => {
    if (props.cropTypes && props.cropTypes.length && cropType === null) {
      setCropType(props.cropTypes[0]);
    }
  }, [props.cropTypes, cropType]);

  useEffect(() => {
    if (props.newLocation && location === null) {
      setLocation(props.newLocation);
    }
  }, [props.newLocation, location]);

  return (
    <Dialog
      fullWidth
      scroll="paper"
      maxWidth="xs"
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        Subir muestras
      </DialogTitle>
      <DialogContent className={classes.content}>
        {props.jobs.length === 0 &&
          <>
            <div className={classes.dropzoneFormRow}>
              <FormControl
                fullWidth={true}
                variant="outlined"
                size="small"
              >
                <InputLabel>Cultivo</InputLabel>
                <Select
                  label="Cultivo"
                  value={cropType ? cropType.id : ''}
                  onChange={e => handleUpdateCropType(e.target.value as string)}
                >
                  {(props.cropTypes || []).map(dataset =>
                    <MenuItem key={dataset.id} value={dataset.id}>
                      {dataset.name}
                    </MenuItem>  
                  )}
                </Select>
              </FormControl>
            </div>

            <div className={classes.dropzoneFormRow}>
              <FormControl
                fullWidth={true}
                variant="outlined"
                size="small"
              >
                <InputLabel>Ubicación</InputLabel>
                <Select
                  label="Ubicación"
                  value={location ? location.id : ''}
                  onChange={e => handleUpdateLocation(e.target.value as string)}
                >
                  <MenuItem value="">
                    <em>Ninguna</em>
                  </MenuItem>  
                  {(props.locations || []).map(dataset =>
                    <MenuItem key={dataset.id} value={dataset.id}>
                      {dataset.name}
                    </MenuItem>  
                  )}
                </Select>
              </FormControl>

              <IconButton
                size="small"
                className={classes.dropzoneIconButton}
                onClick={props.onOpenLocation}
              >
                <AddLocationIcon />
              </IconButton>
            </div>

            <div className={classes.dropzoneArea} {...getRootProps()}>
              <input {...getInputProps()} />
              {acceptedFiles.length === 0 &&
                <>
                  <UploadIcon className={classes.dropzoneIcon} />
                  <Typography className={classes.dropzoneText}>
                    Soltar imágenes<br/>
                    <span className={classes.dropzoneTextLight}>ó</span><br/>
                    Clickear aquí seleccionarlas
                  </Typography>
                </>
              }
              {acceptedFiles.length > 0 &&
                <>
                  <Typography className={classes.dropzoneTitle}>
                    {acceptedFiles.length}
                  </Typography>
                  <Typography className={classes.dropzoneText}>
                    Imágenes seleccionadas
                  </Typography>
                </>
              }
            </div>
          </>
        }
        {props.jobs.length > 0 &&
          <List className={classes.list}>
            {props.jobs.map((job, index) =>
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={job.file.name}
                  secondary={getJobStatus(job)}
                />
                <ListItemSecondaryAction>
                  {getJobIcon(job)}
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        }
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
        >
          Cerrar
        </Button>

        {props.jobs.length === 0 &&
          <Button
            color="primary"
            variant="outlined"
            disabled={!acceptedFiles.length || !cropType || !location}
            onClick={handleSend}
          >
            Subir imágenes
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

export default UploadDialog;
