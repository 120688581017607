import { Dispatch } from 'redux';
import { AppState } from '../reducers';
import { UploadAction } from '../reducers/Upload';
import { Dataset } from '../models/Sample';

export interface UploadProvider {
  startUpload: (files: File[], crop: Dataset, location: Dataset) => void;
}

const startUpload = (files: File[], crop: Dataset, location: Dataset) => {

  return async (dispatch: Dispatch<UploadAction>, getState: () => AppState) => {

    dispatch({ type: 'UPLOAD_CREATED', files, crop, location });

    for (let index = 0; index < getState().upload.jobs.length; index++) {

      dispatch({ type: 'UPLOAD_SENDING', index });

      const job = getState().upload.jobs[index];

      try {
      
        let response = await fetch(`${process.env.REACT_APP_API_URL}/samples`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${ getState().auth.token }`
          },
          body: JSON.stringify({
            datasets: [
              job.crop,
              job.location
            ],
            location: job.location.metadata.geometry.location
          })
        });

        let body = await response.json();

        if (response.status !== 200) throw new Error(body.error);

        const { id, upload } = body;

        const formData = new FormData();

        for (const key in upload.fields) {
          formData.append(key, upload.fields[key]);
        }

        formData.append('file', job.file);

        response = await fetch(upload.url, { method: 'POST', body: formData });

        if (response.status !== 204) throw new Error(body);

        response = await fetch(`${process.env.REACT_APP_API_URL}/samples/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${ getState().auth.token }`
          },
          body: JSON.stringify({ status: 'available' })
        });

        body = await response.json();

        if (response.status !== 200) throw new Error(body.error);

        dispatch({ type: 'UPLOAD_DONE', index });

      } catch (error) {

        console.log(error.message);

        dispatch({ type: 'UPLOAD_FAILED', index, error: error.message });

      }

    }

  }

}

const uploadProvider: UploadProvider = { startUpload };

export default uploadProvider;