import React, { useState, useMemo, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type AutocompletionRequest = google.maps.places.AutocompletionRequest;

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (prediction: AutocompletePrediction) => void;
}

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: '#FFF',
    backgroundColor: theme.palette.primary.light,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  content: {
    padding: 0,
  },
  searchBox: {
    padding: theme.spacing(2),
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const LocationDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const autocompleteService = new window.google.maps.places.AutocompleteService();

  const [value, setValue] = useState<AutocompletePrediction|null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<AutocompletePrediction[]>([]);

  const fetch = useMemo(
    () =>
      throttle((request: AutocompletionRequest, callback: (results: (AutocompletePrediction[] | null)) => void) =>
        autocompleteService.getPlacePredictions(request, callback),
      200),
    []
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      { input: inputValue },
      (results: (AutocompletePrediction[] | null)) => {
        if (active) {
          let newOptions = [] as AutocompletePrediction[];
          if (value) newOptions = [value];
          if (results) newOptions = [...newOptions, ...results];
          setOptions(newOptions);
        }
      }
    );

    return () => { active = false; };
  }, [value, inputValue, fetch]);

  const handleSave = () => {
    if (value) props.onSave(value);
  };

  return (
    <Dialog
      fullWidth
      scroll="paper"
      maxWidth="sm"
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        Agregar ubicación
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.searchBox}>
          <Autocomplete
            noOptionsText="Sin resultados"
            fullWidth={true}
            autoComplete={true}
            includeInputInList={true}
            filterSelectedOptions={true}
            options={options}
            value={value}
            filterOptions={x => x}
            getOptionLabel={option => typeof option === 'string' ? option : option.description}
            onChange={(event: any, newValue: AutocompletePrediction | null) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth={true}
                label="Ubicación"
                variant="outlined"
              />
            )}
            renderOption={option => {
              const matches = option.structured_formatting.main_text_matched_substrings;
              const parts = parse(
                option.structured_formatting.main_text,
                matches.map((match: any) => [match.offset, match.offset + match.length]),
              );

              return (
                <Grid container alignItems="center">
                  <Grid item>
                    <LocationOnIcon className={classes.icon} />
                  </Grid>
                  <Grid item xs>
                    {parts.map((part, index) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                      </span>
                    ))}
                    <Typography variant="body2" color="textSecondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          Cerrar
        </Button>

        <Button
          color="primary"
          variant="outlined"
          disabled={value === null}
          onClick={handleSave}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LocationDialog;
