import { AlertSeverity } from '../models/Snackbar';

export interface SnackbarAction {
  type: 'SHOW_ALERT' | 'CLEAR_ALERT';
  severity?: AlertSeverity;
  message?: string;
}

export interface SnackbarState {
  severity?: AlertSeverity;
  message?: string;
  show: boolean;
}

const SnackbarReducer = (state: SnackbarState = { show: false }, action: SnackbarAction): SnackbarState => {
  switch (action.type) {
    case 'SHOW_ALERT':
      return {
        show: true,
        severity: action.severity,
        message: action.message
      };
    case 'CLEAR_ALERT':
      return {
        ...state,
        show: false
      };
    default:
      return state;
  }
}

export default SnackbarReducer;