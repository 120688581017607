
export interface AuthAction {
  type: 'SESSION_LOADING' | 'SESSION_SUCCESS' | 'SESSION_FAILED' | 'SESSION_EXPIRED';
  token?: string;
  score?: number;
}

export interface AuthState {
  started: boolean;
  loading?: boolean;
  success?: boolean;
  token?: string;
  score?: number;
}

const initialState = { started: false };

const AuthReducer = (state: AuthState = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'SESSION_LOADING':
      return {
        started: true,
        loading: true,
        success: false
      };
    case 'SESSION_SUCCESS':
      return {
        started: true,
        loading: false,
        success: true,
        token: action.token,
        score: action.score
      };
    case 'SESSION_FAILED':
    case 'SESSION_EXPIRED':
      return {
        started: true,
        loading: false,
        success: false
      };
    default:
      return state;
  }
}

export default AuthReducer;
