import { Dataset } from '../models/Sample';

export interface UploadAction {
  type: 'UPLOAD_CREATED' | 'UPLOAD_SENDING' | 'UPLOAD_DONE' | 'UPLOAD_FAILED';
  files?: File[];
  crop?: Dataset;
  location?: Dataset;
  index?: number;
  error?: string;
}

export interface Job {
  file: File;
  crop: Dataset;
  location: Dataset;
  sending: boolean;
  sent: boolean;
  error?: string;
}

export interface UploadState {
  jobs: Job[];
}

const initialState = { jobs: [] as Job[] };

const UploadReducer = (state: UploadState = initialState, action: UploadAction): UploadState => {
  switch (action.type) {
    case 'UPLOAD_CREATED':
      return {
        jobs: action.files!.map(file => ({
          file,
          crop: action.crop!,
          location: action.location!,
          sending: false,
          sent: false
        }))
      };
    case 'UPLOAD_SENDING':
      return {
        jobs: state.jobs.map(
          (job, index) => index === action.index! ? ({
            ...job, sending: true
          }) : job
        )
      };
    case 'UPLOAD_DONE':
      return {
        jobs: state.jobs.map(
          (job, index) => index === action.index! ? ({
            ...job, sending: false, sent: true
          }) : job
        )
      };
    case 'UPLOAD_FAILED':
      return {
        jobs: state.jobs.map(
          (job, index) => index === action.index! ? ({
            ...job, sending: false, error: action.error
          }) : job
        )
      };
    default:
      return state;
  }
}

export default UploadReducer;