import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

interface Props {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: '#FFF',
    backgroundColor: theme.palette.primary.light,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  content: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    '& h6': {
      fontWeight: 500
    },
    '& p': {
      fontWeight: 300
    },
    '& strong': {
      fontWeight: 500
    }
  },
}));

const TermsAndConditionsDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      scroll="paper"
      maxWidth="md"
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        Términos y Condiciones
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="h6" gutterBottom>Programa de colaboración para el desarrollo de Eco Sniper II</Typography>
        <Typography variant="body1" gutterBottom><strong>“Programa de colaboración para el desarrollo de Eco Sniper II”</strong> (de aquí en más el “Programa”) es, esencialmente, un Programa de colaboración para el desarrollo e investigación de “Eco Sniper II”, a través del cual <strong>MILAR AGRO TECH S.R.L.</strong> (en adelante, “MILAR”) pretende gratificar a aquellas personas que, toman y cargan fotografías, conforme al Protocolo en “Anexo I”, adheridos al Programa. Con tal fin, MILAR recompensa a los participantes, en la modalidad que se define más abajo; todo esto sujeto a los términos y condiciones aquí establecidos (el “Reglamento”).</Typography>
        <Typography variant="body1" gutterBottom>1. GENERALIDADES:</Typography>
        <Typography variant="body1" gutterBottom>1.1. Podrá participar del Programa toda persona física mayor de 18 (dieciocho) años de edad.-</Typography>
        <Typography variant="body1" gutterBottom>1.2. Para participar en el Programa deberán suscribirse el presente Reglamento a modo de contrato de adhesión, conforme los arts. 984 a 989 del Código civil y Comercial de la Republica Argentina.-</Typography>
        <Typography variant="body1" gutterBottom>1.3. a) PUNTO: Los Participantes recibirán una puntuación que se verá reflejada bajo unidades denominadas en forma singular punto y en forma plural puntos (en adelante “Punto” y/o “Puntos”) por cada fotografía valida subida a la plataforma indicada (http://label.milar.farm/) o que se indicara, y siguiendo el procedimiento del protocolo obrante en “Anexo I”. Dichos Puntos quedarán registrados en el Sistema Centralizado del Programa (en adelante el “SCP”) de propiedad de MILAR o en cualquier otro medio que utilice MILAR para esos registros.</Typography>
        <Typography variant="body1" gutterBottom>1.3. b) CARACTERÍSTICAS DE LOS PUNTOS: Los Puntos no tienen ningún valor comercial, por ende, no son negociables ni canjeables por dinero. No son transferibles ni transmisibles.-</Typography>
        <Typography variant="body1" gutterBottom>1.4. Los Puntos acreditados, podrán ser canjeables por (i)  premios especiales en la modalidad canje de Puntos, modalidad que se detalla en el punto 4.1 y 4.2; conforme el catalogo de premios vigente de MILAR.-</Typography>
        <Typography variant="body1" gutterBottom>1.5. En ningún caso y bajo ninguna circunstancia estará permitido canjear los Puntos y/o premios especiales por dinero en efectivo.-</Typography>
        <Typography variant="body1" gutterBottom>1.6. Los Participantes podrán acceder a carta de recomendación, expedida por MILAR al único fin de ser incorporada en Referencias Laborales y/o Experiencia y/o Antecedentes en C.V. o presentaciones de índole académicos.-</Typography>
        <Typography variant="body1" gutterBottom>1.7. MILAR se reserva el derecho de variar los términos y condiciones de este Reglamento, como así también los regalos especificados en la Catalogo, en tanto fuera necesario, durante el desarrollo del Programa.-</Typography>
        <Typography variant="body1" gutterBottom>1.8. La participación en el Programa implica la aceptación de todas las condiciones estipuladas en el presente Reglamento, las que son conocidas por los Participantes.-</Typography>
        <Typography variant="body1" gutterBottom>1.9. Cualquier cuestión que se suscite con el Participante en relación con el Programa, será resuelta en forma definitiva e inapelable por MILAR.-</Typography>
        <Typography variant="body1" gutterBottom>1.10. La adhesión al Programa implica la autorización del Participante para la utilización de imágenes y/o datos de las tomas de imágenes y/o ubicación, con fines de investigación y/o desarrollo tecnológico y/o comerciales, en los medios y formas que MILAR determine, sin derecho a compensación alguna, por todo el plazo de duración del Programa a partir de la fecha de adhesión.-</Typography>
        <Typography variant="body1" gutterBottom>1.11. La adhesión al Programa implica que el Participante cede el derecho de propiedad de la imágenes que se carguen y/o suban en la plataforma determinada o a determinar a favor  de MILAR.-</Typography>
        <Typography variant="body1" gutterBottom>1.12. El Participante no podrán divulgar, reproducir, modificar, hacer pública o poner a disposición de terceros la INFORMACION CONFIDENCIAL o la que se presuma que es de carácter confidencial y de propiedad exclusiva de MILAR AGRO TECH S.R.L., salvo la que sea de conocimiento público, sin previa autorización escrita y expresa de MILAR.-</Typography>
        <Typography variant="body1" gutterBottom>1.13. La adhesión al Programa implica el deber de confidencialidad y no divulgación de información (conf. Art. 992 del Código Civil y Comercial de la Republica Argentina).-</Typography>
        <Typography variant="body1" gutterBottom>2. REGISTRO:</Typography>
        <Typography variant="body1" gutterBottom>2.1. El Participante será identificado mediante un código único de identificación (en adelante CUI”).-</Typography>
        <Typography variant="body1" gutterBottom>2.2. La cuenta identificada por el CUI del Participante es personal e intransferible y sólo puede ser utilizada por la persona a cuyo nombre está registrado en el sistema, es decir el Participante (en adelante, la “Cuenta”).-</Typography>
        <Typography variant="body1" gutterBottom>2.3. La adhesión al Programa será valida con la suscripción del presente con datos completos, y la misma aceptada por MILAR.-</Typography>
        <Typography variant="body1" gutterBottom>2.4. MILAR se reserva el derecho a suspender la Cuenta, a su sólo criterio, cuando se haga un uso indebido o inconveniente de ésta.-</Typography>
        <Typography variant="body1" gutterBottom>3. MODO DE OBTENCIÓN DE PUNTOS:</Typography>
        <Typography variant="body1" gutterBottom>3.1. El Participante podrá sumar Puntos con cada fotografía cargada y/o subida a la plataforma (“Anexo I”). </Typography>
        <Typography variant="body1" gutterBottom>3.2. Los Puntos obtenidos durante el desarrollo del Programa podrán ser consultados por el Participante por medio de un correo electrónico a info@milar.farm o virginia.caucino@milar.farm o mecanismo que MILAR determine y que sea oportunamente informado sin perjuicio de lo cual, de surgir diferencias entre la información registrada en la cuenta y lo registrado por el Participante, prevalecerá el cómputo de Puntos registrado en lo primero.-</Typography>
        <Typography variant="body1" gutterBottom>3.3. Cada imagen subida a la plataforma, será equivalente a 1 (un) punto. Para que cada punto se acredite, la imagen deberá cumplir con las especificaciones técnicas solicitadas por MILAR en el Anexo 1 (Protocolo para la toma de imágenes)</Typography>
        <Typography variant="body1" gutterBottom>4. OBTENCIÓN BENEFICIOS:</Typography>
        <Typography variant="body1" gutterBottom>4.1. Premios Especiales; El Participante podrá obtener estos regalos bajo la modalidad de canje de Puntos. Para la obtención de éstos, deberá tener registrada en el SCP la cantidad de Puntos que el premio prevé para su obtención.-</Typography>
        <Typography variant="body1" gutterBottom>4.2. Otros premios: MILAR se reserva el derecho de agregar e integrar al Programa otros productos y/o formas de canjear Puntos. Los términos y condiciones para la obtención de estos regalos serán detallados oportunamente en la Oferta de premios.-</Typography>
        <Typography variant="body1" gutterBottom>4.3. Ninguno de los BENEFICIOS podrá ser canjeado por dinero en efectivo. Al respecto, será de aplicación lo previsto en el punto 1.5.</Typography>
        <Typography variant="body1" gutterBottom>4.4. La entrega de los “REGALOS o PREMIOS” ofrecidos en el marco del Programa estará sujeta a su disponibilidad. En caso de que frente a un producto defectuoso el regalo solicitado no estuviere disponible o no pudiese ser obtenido en condiciones razonables, MILAR podrá ofrecer la devolución de los Puntos canjeados los que serán acreditados en la Cuenta del Participante.-</Typography>
        <Typography variant="body1" gutterBottom>5. VIGENCIA DE PUNTOS – PÉRDIDA DE VIGENCIA DE PUNTOS POR INACTIVIDAD:</Typography>
        <Typography variant="body1" gutterBottom>5.1. Los Puntos acumulados por el Participante, de acuerdo con lo establecido en los puntos 1.3 y 3, se encontrarán vigentes y podrán ser utilizados en la manera descripta en el punto 1.4 durante un plazo que le participante permanezca activo en el Programa.-</Typography>
        <Typography variant="body1" gutterBottom>5.2. Los Puntos de aquellos Participantes que no acrediten actividad en el Programa de acuerdo con lo establecido en los puntos 1.3 y 3 por un período igual o mayor a seis (6) meses, perderán su vigencia; quedando excluido del mismo, operando la disolución, permaneciendo vigente la obligación de los puntos 1.12 y 1.13, por el plazo de 3 (tres) años.-</Typography>
        <Typography variant="body1" gutterBottom>6. PREMIOS ESPECIALES:</Typography>
        <Typography variant="body1" gutterBottom>6.1. MILAR se reserva la facultad de otorgar premios especiales entre los Participantes los que, oportunamente, serán puestos en su conocimiento a través de los canales de comunicación habituales y/o por cualquier otro medio que MILAR considere conveniente.-</Typography>
        <Typography variant="body1" gutterBottom>7. DATOS y/o INFORMACION y/o FOTOGRAFIAS PERDIDAS, DAÑADAS, ROBADAS Y/ O HURTADAS:</Typography>
        <Typography variant="body1" gutterBottom>7.1. El Participante deberá denunciar en forma inmediata comunicándolo a info@milar.farm y/o virginia.caucino@milar.farm cualquier pérdida, deterioro substancial, robo o hurto de datos, información y/o fotografías.-</Typography>
        <Typography variant="body1" gutterBottom>7.2. Efectuada la denuncia, MILAR a su exclusivo criterio, evaluará la necesidad de adoptar alguna medida de resguardo.-</Typography>
        <Typography variant="body1" gutterBottom>7.3. MILAR no será responsable del uso indebido y/o fraudulento que se efectúe de las fotografías obtenidas por el/los participante/s.-</Typography>
        <Typography variant="body1" gutterBottom>7.4. MILAR se reserva el derecho de iniciar las acciones legales que correspondan contra cualquier persona que intente realizar un uso indebido de las fotografías, y/o divulgación de información confidencial, en los términos de los puntos 1.12 y 1.13.-</Typography>
        <Typography variant="body1" gutterBottom>8. USO INDEBIDO:</Typography>
        <Typography variant="body1" gutterBottom>8.1. MILAR podrá dar de baja una Cuenta y/o Participante y anular los Puntos acumulados en la misma si detectara un uso indebido. La baja de una Cuenta implicará la caducidad inmediata de todos los Puntos asociados a la misma.-</Typography>
        <Typography variant="body1" gutterBottom>9. ACTUALIZACIÓN DEL DATOS DEL PARTICIPANTE:</Typography>
        <Typography variant="body1" gutterBottom>9.1. Cualquier cambio de domicilio, teléfono, dirección de correo electrónico, etc., del Participante deberá notificar en forma inmediata a MILAR mediante correo electrónico a info@milar.farm y/o virginia.caucino@milar.farm, con el asunto “Actualización de Datos.-</Typography>
        <Typography variant="body1" gutterBottom>10. ATENCIÓN AL PARTICIPANTE:</Typography>
        <Typography variant="body1" gutterBottom>10.1.  El Participante, ante cualquier duda, consulta o inconveniente que se le presentare en relación con el Programa podrá contactar al Centro de Contactos a MILAR por los canales de comunicación habitual, siendo estos info@milar.farm y/o virginia.caucino@milar.farm o cualquier otra vía de contacto que MILAR disponga y comunique a tal fin, en los días y horarios para ello establecido por MILAR.-</Typography>
        <Typography variant="body1" gutterBottom>11. VIGENCIA DEL PROGRAMA:</Typography>
        <Typography variant="body1" gutterBottom>11.1. MILAR se reserva el derecho a modificar los términos del presente Reglamento. -  </Typography>
        <Typography variant="body1" gutterBottom>11.1. MILAR se reserva el derecho de suspender y/o concluir el Programa notificando tal decisión con 60 (sesenta) días de anticipación a la fecha de cierre, por los medios de comunicación habitual entre MILAR y el Participante. Los Participantes podrán hacer uso de sus Puntos durante el mencionado plazo. Finalizado el mismo caducará todo derecho a hacerlo.-</Typography>
        <Typography variant="body1" gutterBottom>12. LEY APLICABLE – EXIMICION DE RESPONSABILIDAD - JURISDICCIÓN:</Typography>
        <Typography variant="body1" gutterBottom>12.1. El presente Reglamento se regirá, cumplirá y ejecutará conforme a la ley argentina.-</Typography>
        <Typography variant="body1" gutterBottom>12.2. El participante exime total e ilimitadamente a MILAR de toda responsabilidad por daño y/o perjuicio que pudiera ser ocasionado para si o tercero en el desarrollo de la/s actividad/es que implican la participación en el Programa. Esta eximición es de índole Civil y/o Comercial y/o Penal y/o administrativa y/o contravencional. -</Typography>
        <Typography variant="body1" gutterBottom>12.3. Toda controversia que se suscite entre las Partes con relación a este Reglamento, su existencia, validez, calificación, interpretación, alcance, cumplimiento o resolución, se resolverá definitivamente por los Juzgados de Primera Instancia en los Civil y Comercial de Tres Arroyos, Dpto. Judicial de Bahía Blanca, Provincia de Buenos Aires.-.</Typography>
        <br/>
        <Typography variant="body1" gutterBottom>Los presentes términos y condiciones entrarán en vigencia a partir del día 01/07/2021. -</Typography>
        <br/>
        <Typography variant="h6" gutterBottom>POLITICAS DE PRIVACIDAD DE DATOS:</Typography>
        <Typography variant="body1" gutterBottom>Alcance: Por medio de las políticas de Privacidad se determina el tratamiento que MILAR dará a los datos recibidos por parte de los Participantes y que estarán en un todo de acuerdo con lo establecido en la Ley Nacional de Datos Personales N°25.326.-</Typography>
        <Typography variant="body1" gutterBottom>De esta manera, en aquellos casos que los Participantes aporten datos personales o información confidencial a MILAR, están aceptando las presentes Políticas juntamente con los Términos.- </Typography>
        <Typography variant="body1" gutterBottom>Para ello MILAR proporciona a los Participantes los recursos adecuados para que tomen conocimiento de las presentes Políticas de Privacidad, los Términos y Condiciones de uso y Protección de Datos Personales.-</Typography>
        <Typography variant="body1" gutterBottom>Las presentes Políticas describen:</Typography>
        <Typography variant="body1" gutterBottom>· Cuáles serán los datos recolectados, como serán utilizados y la finalidad para los que serán tratados.-</Typography>
        <Typography variant="body1" gutterBottom>· Como la empresa protege los datos de carácter personal de los Participantes. Facultad y modo de ejercer los derechos de acceso, rectificación, actualización y supresión de los datos.-</Typography>
        <Typography variant="body1" gutterBottom>· Las consecuencias que deriven de proporcionar los datos o de la inexactitud de estos.-</Typography>
        <Typography variant="body1" gutterBottom>a) Cuáles serán los datos recolectados, como serán utilizados y la finalidad para los que serán tratados.</Typography>
        <Typography variant="body1" gutterBottom>MILAR recolecta varios tipos de información y contenido que directa o indirectamente el Participante provee, siendo estos: Nombre, DNI, fecha de nacimiento, teléfono, e-mail, dirección y código postal.</Typography>
        <Typography variant="body1" gutterBottom>MILAR también podrá recopilar datos de un modo que, por si mismos, no pueden ser asociados directamente a una persona determinada. Estos datos de carácter no personal se pueden recopilar, tratar, transferir y publicar con cualquier intención. Recolectamos información acerca de cómo el Usuario usa la Plataforma de carga de imágenes, y dependiendo los permisos de acceso que ha habilitado para su dispositivo, otra información, como la que se especifica más abajo, desde o acerca de que computadoras o teléfono móvil u otro dispositivo instale el acceso a la Plataforma. La Plataforma utiliza tecnologías de internet estándar, tales como cookies* con la finalidad de recolectar información acerca de su dispositivo o computadora y su actividad en línea.-</Typography>
        <Typography variant="body1" gutterBottom>*cookies: son pequeños archivos que se alojan en la computadora o dispositivo del Usuario. Una cookie permite a la entidad que coloca la cookie en su dispositivo reconocerla en diferentes sitios web, etc. La Plataforma utiliza cookies para análisis respecto de cómo el Usuario utiliza la Plataforma.</Typography>
        <Typography variant="body1" gutterBottom>La información que se recolecta al respecto es:</Typography>
        <Typography variant="body1" gutterBottom>-  Su tipo de browser y sistema operativo.</Typography>
        <Typography variant="body1" gutterBottom>-  Dirección IP e identificadores de los dispositivos que utilice.</Typography>
        <Typography variant="body1" gutterBottom>-  El comportamiento de su browser respecto las interacciones en la Plataforma, tales como, el tiempo de permanencia.</Typography>
        <Typography variant="body1" gutterBottom>-  Datos acerca si ha abierto o reenviado los e-mails que enviamos durante o relacionados a la interacción con la Plataforma y/o con el Programa.</Typography>
        <Typography variant="body1" gutterBottom>Los datos de carácter personal se encontrarán alojados en servers propios o de terceros contratados por MILAR pudiendo localizarse los mismos en otros países; comprometiéndose MILAR a tomar los recaudos necesarios para su mejor conservación y cuidado.-</Typography>
        <Typography variant="body1" gutterBottom>Divulgación a Terceros:</Typography>
        <Typography variant="body1" gutterBottom>Ocasionalmente MILAR puede facilitar determinados datos de carácter personal a terceros estratégicos que vinculados con MILAR.</Typography>
        <Typography variant="body1" gutterBottom>Otros terceros:</Typography>
        <Typography variant="body1" gutterBottom>Es posible que MILAR divulgue datos de carácter personal por mandato legal, en el marco de un proceso judicial o por petición de una autoridad, tanto dentro o fuera del País. Igualmente se puede publicar información personal si es necesaria o conveniente por motivos de seguridad nacional, para acatar legislación vigente o por otras razones relevantes de interés público.</Typography>
        <Typography variant="body1" gutterBottom>Los datos solicitados serán utilizados por MILAR exclusivamente para su inclusión en las Bases de datos de empresa. MILAR nunca utilizará los datos personales de los Participantes para ningún fin distinto a los mencionados en estas Políticas.-</Typography>
        <Typography variant="body1" gutterBottom>b)   Cómo la empresa protege los datos de carácter personal de los Participantes.</Typography>
        <Typography variant="body1" gutterBottom>MILAR garantizará la protección de los datos personales mediante cifrado durante el tránsito y, los alojados en instalaciones, con medidas de seguridad físicas.-</Typography>
        <Typography variant="body1" gutterBottom>Integridad y conservación de los datos de carácter personal: MILAR garantizará la exactitud y la calidad de los datos personales, los mismos se conservarán durante el tiempo necesario para cumplir los fines para los cuales fueron recabados, salvo que la ley exija conservarlos durante más tiempo.-</Typography>
        <Typography variant="body1" gutterBottom>Derechos de los titulares: los titulares de datos personales tienen la facultad de ejercer el derecho de acceso a los mismos en forma gratuita, acreditando debidamente su identidad a tal efecto, a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº 25.326. Asimismo, cuando correspondiere, los titulares de datos personales tienen el derecho de ejercer los derechos de rectificación y supresión de los datos.-</Typography>
        <Typography variant="body1" gutterBottom>Para el ejercicio de los derechos, el Solicitante deberá presentar una solicitud, ya sea de manera presencial o bien a distancia, por cualquiera de los siguientes medios: mensajería electrónica, carta documento y/o presentación escrita con firma.-</Typography>
        <Typography variant="body1" gutterBottom>c) Las consecuencias que deriven de proporcionar los datos o de la inexactitud de estos.</Typography>
        <Typography variant="body1" gutterBottom>El Participante asume el compromiso de actualizar los Datos Personales conforme resulte necesario. MILAR no se responsabiliza por la certeza de los Datos Personales provistos por los Participantes. Los Participantes garantizan y responden, en cualquier caso, por la veracidad, exactitud, integridad, vigencia y autenticidad de los Datos Personales ingresados. Toda la información y los Datos Personales ingresados por el Participante tienen carácter de declaración jurada.-</Typography>
        <Typography variant="body1" gutterBottom>MILAR se reserva el derecho de solicitar documentación y/o información adicional a efectos de corroborar la información entregada por un Participante en materia de Datos Personales, así como de suspender temporal o definitivamente a aquellos Participantes cuyos datos no hayan podido ser confirmados.-        </Typography>
        <Typography variant="body1" gutterBottom>MILAR se reserva el derecho de rechazar una solicitud de registro o de cancelar o suspender, temporal o definitivamente una cuenta, en caso de detectar incongruencias o inconsistencias en la información provista por un Participante o en caso de detectar actividades sospechosas, sin que tal decisión genere para el Participante derechos de indemnización o resarcimiento alguno.-</Typography>
        <br/>
        <Typography variant="body1" gutterBottom><strong>La suscripción del presente, implica el conocimiento del Reglamento, términos y condiciones, como así también del “Protocolo para la toma de fotografías de cultivos agrícolas” obrante en “Anexo I” y la intención de participar en el “Programa de colaboración para el desarrollo de Eco Sniper II”. La incorporación al Programa esta supeditado a la aceptación de la solicitud por parte de MILAR AGRO TECH S.R.L.</strong></Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default TermsAndConditionsDialog;
