
export interface RegisterAction {
  type: 'REGISTER_LOADING' | 'REGISTER_SUCCESS' | 'REGISTER_FAILED';
}

export interface RegisterState {
  loading: boolean;
  success?: boolean;
  failed?: boolean;
}

const initialState = { loading: false };

const RegisterReducer = (state: RegisterState = initialState, action: RegisterAction): RegisterState => {
  switch (action.type) {
    case 'REGISTER_LOADING':
      return {
        loading: true
      };
    case 'REGISTER_SUCCESS':
      return {
        loading: false,
        success: true
      };
    case 'REGISTER_FAILED':
      return {
        loading: false,
        failed: true
      };
    default:
      return state;
  }
}

export default RegisterReducer;