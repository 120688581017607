import { Dispatch } from 'redux';
import { AppState } from '../reducers';
import { AuthAction } from '../reducers/Auth';

export interface AuthProvider {
  fetchSession: (getAccessToken: () => Promise<string>) => void;
  setToken: (token: string) => void;
}

const fetchSession = (getAccessToken: () => Promise<string>) => {

  return async (dispatch: Dispatch<AuthAction>, getState: () => AppState) => {

    dispatch({ type: 'SESSION_LOADING' });

    try {

      const token = await getAccessToken();

      if (token) {

        const response = await fetch(`${process.env.REACT_APP_API_URL}/session`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${ token }`
          }
        });

        const body = await response.json();

        if (response.status !== 200) throw new Error(body);
          
        dispatch({ type: 'SESSION_SUCCESS', token: body.token || undefined, score: body.score || 0 });

      } else {

        dispatch({ type: 'SESSION_FAILED' });

      }

    } catch (error) {

      console.log(error.message);

      dispatch({ type: 'SESSION_FAILED' });

    }

  }

}

const setToken = (token: string) => {

  return async (dispatch: Dispatch<AuthAction>, getState: () => AppState) => {

    dispatch({ type: 'SESSION_SUCCESS', token });

  }

}

const authProvider: AuthProvider = { fetchSession, setToken };

export default authProvider;