import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

import DrawIcon from '@material-ui/icons/BorderColor';
import EditIcon from '@material-ui/icons/CropFree';
import UploadIcon from '@material-ui/icons/CloudUpload';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomResetIcon from '@material-ui/icons/ZoomOutMap';

import { TOOLBAR_WIDTH, APPBAR_HEIGHT } from '.';

interface Props {
  mode: string;
  onChangeMode: (mode: string) => void;
  onOpenUpload: () => void;
}

const useStyles = makeStyles(theme => ({
  toolbar: {
    width: TOOLBAR_WIDTH,
    flexShrink: 0,
  },
  toolbarPaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: TOOLBAR_WIDTH,
    overflow: 'visible',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(.5),
    paddingTop: APPBAR_HEIGHT + theme.spacing(1),
  },
  toolbarButton: {
    display: 'block',
    marginBottom: theme.spacing(.5),
  },
  toolbarButtonActive: {
    display: 'block',
    marginBottom: theme.spacing(.5),
    color: '#FFF',
    backgroundColor: 'rgba(255,255,255,.25)',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Toolbar: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      variant="permanent"
      className={classes.toolbar}
      classes={{paper: classes.toolbarPaper}}
    >
      <div>
        <IconButton
          className={props.mode === 'draw' ? classes.toolbarButtonActive : classes.toolbarButton}
          onClick={() => props.onChangeMode('draw')}
        >
          <DrawIcon />
        </IconButton>
        
        <IconButton
          className={props.mode === 'edit' ? classes.toolbarButtonActive : classes.toolbarButton}
          onClick={() => props.onChangeMode('edit')}
        >
          <EditIcon />
        </IconButton>
        
        <Divider className={classes.divider} />
        
        <IconButton className={classes.toolbarButton}>
          <ZoomInIcon />
        </IconButton>

        <IconButton className={classes.toolbarButton}>
          <ZoomOutIcon />
        </IconButton>

        <IconButton className={classes.toolbarButton}>
          <ZoomResetIcon />
        </IconButton>
      </div>
      <div>
        <IconButton onClick={props.onOpenUpload}>
          <UploadIcon />
        </IconButton>
      </div>
    </Drawer>
  );
}

export default Toolbar;
