import React from 'react';
import { Layer } from 'react-konva';

import LabelRect from './LabelRect';
import { Label, Box } from '../../models/Editor';

interface Props {
  mode: string;
  labels: Label[];
  sampleBox: Box | null;
  onUpdate: (labels: Label[]) => void;
}

const LabelLayer: React.FC<Props> = (props: Props) => {
  const handleUpdateLabel = (updateIndex: number, newLabel: Label) => {
    props.onUpdate(props.labels.map((label, index) => index === updateIndex ? newLabel : label));
  }

  const handleSelectLabel = (selectIndex: number) => {
    if (props.mode === 'edit') {
      props.onUpdate(props.labels.map((label, index) => ({...label, selected: index === selectIndex})));
    }
  }

  return (
    <Layer>
      {props.labels.map((label, index) =>
        <LabelRect
          key={index}
          mode={props.mode}
          label={label}
          sampleBox={props.sampleBox}
          onUpdate={label => handleUpdateLabel(index, label)}
          onSelect={() => handleSelectLabel(index)}
        />
      )}
    </Layer>
  );
}

export default LabelLayer;
