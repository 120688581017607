import React, { useRef, useEffect } from 'react';
import { Rect, Transformer } from 'react-konva';

import Konva from 'konva';
import { Label, Box } from '../../models/Editor';

interface Props {
  mode: string;
  label: Label;
  sampleBox: Box | null;
  onUpdate: (label: Label) => void;
  onSelect: () => void;
}

const LabelRect: React.FC<Props> = (props: Props) => {
  const rectRef = useRef() as any;
  const trRef = useRef() as any;

  useEffect(() => {
    if (props.label.selected) {
      trRef.current.nodes([rectRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [props.label.selected]);

  const dragBoundFunc = (position: Konva.Vector2d) => {
    if (props.sampleBox !== null) {
      const w = props.label.box.width;
      const h = props.label.box.height;
      let x = position.x;
      let y = position.y;

      if (x < props.sampleBox.x) {
        x = props.sampleBox.x;
      } else if (x + w > props.sampleBox.x + props.sampleBox.width) {
        x = props.sampleBox.x + props.sampleBox.width - w;
      }

      if (y < props.sampleBox.y) {
        y = props.sampleBox.y;
      } else if (y + h > props.sampleBox.y + props.sampleBox.height) {
        y = props.sampleBox.y + props.sampleBox.height - h;
      }

      return { x, y };
    }

    return position;
  };

  const onDragEnd = (event: Konva.KonvaEventObject<DragEvent>) => {
    props.onUpdate({
      ...props.label,
      box: {
        ...props.label.box,
        x: event.target.x(),
        y: event.target.y()
      }
    });
  };

  const onTransform = (event: Konva.KonvaEventObject<Event>) => {
    const rect = rectRef.current as Konva.Rect;
    const scaleX = rect.scaleX();
    const scaleY = rect.scaleY();

    rect.scaleX(1);
    rect.scaleY(1);

    props.onUpdate({
      ...props.label,
      box: {
        x: rect.x(),
        y: rect.y(),
        width: rect.width() * scaleX,
        height: rect.height() * scaleY
      }
    });
  };

  const onTransformEnd = (event: Konva.KonvaEventObject<Event>) => {
    if (props.sampleBox !== null) {
      const rect = rectRef.current as Konva.Rect;
      const rotation = rect.rotation();
      let {x, y} = rect.position();
      let {width, height} = rect.size();

      if (rotation === 0 && height < 0) {
        y -= Math.abs(height);
      } else if (rotation < 0) {
        x -= width;
      } else if (rotation > 0) {
        y -= Math.abs(height);
        x -= width;
      }

      height = Math.abs(height);

      if (x < props.sampleBox.x) x = props.sampleBox.x;
      if (y < props.sampleBox.y) y = props.sampleBox.y;

      if (x + width > props.sampleBox.x + props.sampleBox.width) {
        width = props.sampleBox.x + props.sampleBox.width - x;
      }
      if (y + height > props.sampleBox.y + props.sampleBox.height) {
        height = props.sampleBox.y + props.sampleBox.height - y;
      }

      rect.x(x);
      rect.y(y);
      rect.width(width);
      rect.height(height);
      rect.rotation(0);
      props.onUpdate({
        ...props.label,
        box: {x, y, width, height}
      });
    }
  }

  return (
    <React.Fragment>
      <Rect
        id="selectable"
        ref={rectRef}
        x={props.label.box.x}
        y={props.label.box.y}
        width={props.label.box.width}
        height={props.label.box.height}
        stroke="#FFF"
        strokeWidth={2}
        draggable={props.mode === 'edit'}
        dragBoundFunc={dragBoundFunc}
        onDragEnd={onDragEnd}
        onTransform={onTransform}
        onTransformEnd={onTransformEnd}
        onMouseDown={props.onSelect}
      />
      {props.label.selected &&
        <Transformer
          id="selectable"
          ref={trRef}
          padding={2}
          rotateEnabled={false}
          keepRatio={false}
        />
      }
    </React.Fragment>
  );
}

export default LabelRect;
