import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Toolbar from './Toolbar';
import Drawer from './Drawer';
import EditorStage from './EditorStage';
import ImageLayer from './ImageLayer';
import LabelLayer from './LabelLayer';
import UploadDialog from './UploadDialog';
import LocationDialog from './LocationDialog';
import { Label, Box } from '../../models/Editor';
import { Dataset } from '../../models/Sample';
import { AppState } from '../../reducers';
import { DatasetState } from '../../reducers/Dataset';
import { LabelingState } from '../../reducers/Labeling';
import { UploadState } from '../../reducers/Upload';
import DatasetProvider from '../../providers/Dataset';
import LabelingProvider from '../../providers/Labeling';
import UploadProvider from '../../providers/Upload';
import SnackbarProvider from '../../providers/Snackbar';

export const APPBAR_HEIGHT = 49;
export const TOOLBAR_WIDTH = 57;
export const DRAWER_WIDTH = 350;
export const STAGE_WIDTH = window.innerWidth - TOOLBAR_WIDTH - DRAWER_WIDTH;
export const STAGE_HEIGHT = window.innerHeight - APPBAR_HEIGHT;
export const BORDER_SIZE = 60;

type AutocompletePrediction = google.maps.places.AutocompletePrediction;

interface Props {
  dataset: DatasetState;
  labeling: LabelingState;
  upload: UploadState;
}

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    paddingTop: APPBAR_HEIGHT,
  },
  status: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: `calc(100vw - ${TOOLBAR_WIDTH + DRAWER_WIDTH}px)`,
    height: '100vh',
    marginTop: -APPBAR_HEIGHT,
  },
  statusTitle: {
    margin: theme.spacing(4),
    fontSize: 24,
    fontWeight: 300,
  },
  errorTitle: {
    marginBottom: theme.spacing(1),
    fontSize: 24,
    fontWeight: 300,
  },
  errorSubtitle: {
    marginBottom: theme.spacing(4),
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 500,
    color: '#A00',
  },
  search: {
    width: 500,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    borderColor: 'rgba(0,0,0,.27)',
    padding: theme.spacing(2),
  },
  searchTitle: {
    marginBottom: theme.spacing(2.5),
    fontSize: 19,
    fontWeight: 300,
  },
  searchButton: {
    marginTop: theme.spacing(2),
    float: 'right',
  },
}));

const Editor: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const [mode, setMode] = useState<string>('draw');
  const [labels, setLabels] = useState<Label[]>([]);
  const [sampleBox, setSampleBox] = useState<Box|null>(null);
  const [dataset, setDataset] = useState<Dataset|null>(null);
  const [uploadOpen, setUploadOpen] = useState<boolean>(false);
  const [locationOpen, setLocationOpen] = useState<boolean>(false);

  const handleChangeMode = (mode: string) => {
    handleDeselectAllLabels();
    setMode(mode);
  };

  const handleCreateLabel = (box: Box) => {
    setLabels([...labels, { box }]);
  };

  const handleSelectLabel = (selectIndex: number) => {
    setMode('edit');
    setLabels(labels.map((label, index) => ({...label, selected: index === selectIndex})));
  };

  const handleDeleteLabel = (deleteIndex: number) => {
    setLabels(labels.filter((label, index) => index !== deleteIndex));
  };

  const handleUpdateLabel = (updateIndex: number, updatedLabel: Label) => {
    setLabels(labels.map((label, index) => index === updateIndex ? updatedLabel : label));
  };

  const handleDeselectAllLabels = () => {
    setLabels(labels.map(label => ({...label, selected: false})));
  };

  const handleSelectDataset = (datasetId: string) => {
    if (props.dataset.cropTypes) {
      setDataset(props.dataset.cropTypes.find(dataset => dataset.id === datasetId) || null);
    }
  };

  const handleCreateLocation = (prediction: AutocompletePrediction) => {
    setLocationOpen(false);
    dispatch(DatasetProvider.createLocationDataset(prediction));
  };

  const handleSend = () => {
    if (sampleBox !== null && labels.length > 0) {
      if (labels.filter(label => !label.type).length > 0) {
        dispatch(
          SnackbarProvider.showErrorAlert(
            "Todas las etiquetas deben tener un tipo seleccionado"
          )
        );
      } else {
        dispatch(
          LabelingProvider.updateSample(
            {
              ...props.labeling.fetch.sample!,
              labels: labels.map(label => ({
                class: label.type === 'crop' ? 0 : 1,
                x_center: (label.box.x - sampleBox.x + label.box.width / 2) / sampleBox.width,
                y_center: (label.box.y - sampleBox.y + label.box.height / 2) / sampleBox.height,
                width: label.box.width / sampleBox.width,
                height: label.box.height / sampleBox.height
              }))
            },
            dataset || undefined
          )
        );
      }
    }
  };

  const handleUpload = (files: File[], crop: Dataset, location: Dataset) => {
    dispatch(UploadProvider.startUpload(files, crop, location));
  };

  useEffect(() => {
    dispatch(DatasetProvider.fetchDatasets());
  }, [dispatch]);

  useEffect(() => {
    if (props.labeling.fetch.fetching) {
      setMode('draw');
      setLabels([]);
      setSampleBox(null);
    }
  }, [props.labeling.fetch.fetching]);

  const renderContent = () => {
    if (props.labeling.fetch.fetching) {
      return (
        <div className={classes.status}>
          <CircularProgress size={120} />
          <Typography className={classes.statusTitle}>
            Buscando muestras
          </Typography>
        </div>
      );
    };

    if (!props.labeling.fetch.sample) {
      return (
        <div className={classes.status}>
          {props.labeling.fetch.error &&
            <>
              <Typography className={classes.errorTitle}>
                Error al buscar muestras
              </Typography>
              <Typography className={classes.errorSubtitle}>
                Error: {props.labeling.fetch.error}
              </Typography>
            </>
          }
          <div className={classes.search}>
            <Typography className={classes.searchTitle}>
              Buscar muestras
            </Typography>
            <FormControl fullWidth={true} variant="outlined">
              <InputLabel>Dataset</InputLabel>
              <Select
                label="Dataset"
                value={dataset ? dataset.id : ''}
                onChange={e => handleSelectDataset(e.target.value as string)}
              >
                <MenuItem value=""><em>Ninguno</em></MenuItem>
                {(props.dataset.cropTypes || []).map(dataset =>
                  <MenuItem key={dataset.id} value={dataset.id}>
                    {dataset.name}
                  </MenuItem>  
                )}
              </Select>
            </FormControl>
            <Button
              color="primary"
              variant="outlined"
              className={classes.searchButton}
              onClick={() => dispatch(LabelingProvider.getUnlabeledSample(dataset || undefined))}
            >
              Buscar
            </Button>
          </div>
        </div>
      );
    }

    return (
      <EditorStage
        mode={mode}
        sampleBox={sampleBox}
        onCreateLabel={handleCreateLabel}
        onDeselectAllLabels={handleDeselectAllLabels}
      >
        <ImageLayer
          url={props.labeling.fetch.sample.url}
          onLoaded={setSampleBox}
        />
        <LabelLayer
          mode={mode}
          labels={labels}
          sampleBox={sampleBox}
          onUpdate={setLabels}
        />
      </EditorStage>
    );
  };


  return (
    <div className={classes.root}>
      <Toolbar
        mode={mode}
        onChangeMode={handleChangeMode}
        onOpenUpload={() => setUploadOpen(true)}
      />
    
      {renderContent()}

      <Drawer
        sample={props.labeling.fetch.sample}
        labels={labels}
        onSelectLabel={handleSelectLabel}
        onUpdateLabel={handleUpdateLabel}
        onDeleteLabel={handleDeleteLabel}
        onSend={handleSend}
      />

      <UploadDialog
        open={uploadOpen}
        jobs={props.upload.jobs}
        cropTypes={props.dataset.cropTypes || []}
        locations={props.dataset.locations || []}
        newLocation={props.dataset.newLocation}
        onClose={() => setUploadOpen(false)}
        onOpenLocation={() => setLocationOpen(true)}
        onUpload={handleUpload}
      />

      <LocationDialog
        open={locationOpen}
        onClose={() => setLocationOpen(false)}
        onSave={handleCreateLocation}
      />
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  dataset: state.dataset,
  labeling: state.labeling,
  upload: state.upload
});

export default connect(mapStateToProps, null)(Editor);
