import React, { useState } from 'react';
import Konva from 'konva';
import { Stage, Layer, Rect } from 'react-konva';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '../../models/Editor';
import { STAGE_WIDTH, STAGE_HEIGHT } from '.';

interface Props {
  mode: string;
  sampleBox: Box | null;
  children: JSX.Element | JSX.Element[] | null;
  onCreateLabel: (box: Box) => void;
  onDeselectAllLabels: () => void;
}

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1
  },
  contentDraw: {
    flexGrow: 1,
    cursor: 'crosshair'
  },
}));

const EditorStage: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const [rectBox, setRectBox] = useState<Box|null>(null);
  const [cursorInSampleBox, setCursorInSampleBox] = useState<boolean>(false);

  const onMouseDown = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (
      props.sampleBox !== null && rectBox === null && props.mode === 'draw' &&
      event.target.getStage() !== null && event.target.getStage()!.getPointerPosition() !== null
    ) {
      const x = event.target.getStage()!.getPointerPosition()!.x;
      const y = event.target.getStage()!.getPointerPosition()!.y;

      if (
        x >= props.sampleBox.x && x <= props.sampleBox.x + props.sampleBox.width &&
        y >= props.sampleBox.y && y <= props.sampleBox.y + props.sampleBox.height
      ) {
        setRectBox({x, y, width: 0, height: 0});
      }
    }
    if (event.target.id() !== 'selectable' && event.target.parent?.id() !== 'selectable') {
      props.onDeselectAllLabels();
    }
  };

  const onMouseMove = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (
      props.sampleBox !== null && rectBox !== null && props.mode === 'draw' &&
      event.target.getStage() !== null && event.target.getStage()!.getPointerPosition() !== null
    ) {
      const x = event.target.getStage()!.getPointerPosition()!.x;
      const y = event.target.getStage()!.getPointerPosition()!.y;
      let width = x - rectBox.x;
      let height = y - rectBox.y;

      if (x < props.sampleBox.x) {
        width = props.sampleBox.x - rectBox.x;
      } else if (x > props.sampleBox.x + props.sampleBox.width) {
        width = props.sampleBox.x + props.sampleBox.width - rectBox.x;
      }

      if (y < props.sampleBox.y) {
        height = props.sampleBox.y - rectBox.y;
      } else if (y > props.sampleBox.y + props.sampleBox.height) {
        height = props.sampleBox.y + props.sampleBox.height - rectBox.y;
      }

      setRectBox({ ...rectBox, width, height });
    }
    
    setCursorInSampleBox(props.mode === 'draw' && event.target.id() === 'image');
  };

  const onMouseUp = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (props.mode === 'draw' && rectBox !== null) {
      props.onCreateLabel({
        x: rectBox.width >= 0 ? rectBox.x : rectBox.x + rectBox.width,
        y: rectBox.height >= 0 ? rectBox.y : rectBox.y + rectBox.height,
        width: rectBox.width >= 0 ? rectBox.width : -rectBox.width,
        height: rectBox.height >= 0 ? rectBox.height : -rectBox.height
      });
      setRectBox(null);
    }
  };

  return (
    <Stage
      width={STAGE_WIDTH}
      height={STAGE_HEIGHT}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      className={cursorInSampleBox ? classes.contentDraw : classes.content}
    >
      {props.children}
      <Layer>
        {props.mode === 'draw' && rectBox !== null &&
          <Rect
            stroke="#F00"
            strokeWidth={2}
            {...rectBox}
          />
        }
      </Layer>
    </Stage>
  );
}

export default EditorStage;
