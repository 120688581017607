import { Sample } from '../models/Sample';

export interface LabelingAction {
  type: 'SAMPLE_FETCHING' | 'SAMPLE_FETCH' | 'SAMPLE_FETCH_FAILED' | 'SAMPLE_UPDATING' | 'SAMPLE_UPDATED' | 'SAMPLE_UPDATE_FAILED';
  sample?: Sample;
  error?: string;
}

export interface LabelingState {
  fetch: {
    fetching?: boolean;
    error?: string;
    sample?: Sample;
  },
  update: {
    sending?: boolean;
    error?: string;
    sent?: boolean;
  }
}

const initialState = { fetch: {}, update: {} };

const LabelingReducer = (state: LabelingState = initialState, action: LabelingAction): LabelingState => {
  switch (action.type) {
    case 'SAMPLE_FETCHING':
      return {
        ...state,
        fetch: {
          fetching: true
        }
      };
    case 'SAMPLE_FETCH':
      return {
        ...state,
        fetch: {
          fetching: false,
          sample: action.sample
        }
      };
    case 'SAMPLE_FETCH_FAILED':
      return {
        ...state,
        fetch: {
          fetching: false,
          error: action.error
        }
      };
    case 'SAMPLE_UPDATING':
      return {
        ...state,
        update: {
          sending: true
        }
      };
    case 'SAMPLE_UPDATED':
      return {
        ...state,
        update: {
          sending: false,
          sent: true
        }
      };
    case 'SAMPLE_UPDATE_FAILED':
      return {
        ...state,
        update: {
          sending: false,
          error: action.error
        }
      };
    default:
      return state;
  }
}

export default LabelingReducer;