import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import GiftIcon from '@material-ui/icons/Star';
import HelpIcon from '@material-ui/icons/Help';

import { AppState } from '../../../reducers';
import AuthProvider from '../../../providers/Auth';
import Register from '../Register';

interface Props {
  started?: boolean;
  loading?: boolean;
  success?: boolean;
  token?: string;
  score?: number;
  children: JSX.Element | null;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F9F9F9'
  },
  appBar: {
    color: '#FFF',
    backgroundColor: theme.palette.primary.light,
    boxShadow: 'none',
    zIndex: 1300,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  appBarToolbar: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  appBarTitle: {
    fontFamily: 'Saira',
    fontWeight: 500,
    flexGrow: 1,
    cursor: 'default',
  },
  appBarAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: '2px solid rgba(0, 0, 0, 0.12)',
    cursor: 'pointer',
    transition: 'border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      borderColor: 'rgba(255, 255, 255, .72)',
    },
  },
  appBarButton: {
    marginRight: theme.spacing(1.5),
    borderRadius: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  popoverPaper: {
    top: 49,
    right: 0,
    borderRadius: 0,
    minWidth: 349,
  },
  popoverHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px 12px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  popoverMenuIcon: {
    minWidth: 0,
    marginLeft: theme.spacing(2),
  },
  status: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
  },
}));

const AuthWrapper: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState<boolean>(false);
  const { loginWithRedirect, isAuthenticated, isLoading, user, logout, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if ((!isLoading && !isAuthenticated) || (props.started && !props.loading && !props.success)) {
      loginWithRedirect();
    }
    if (!isLoading && isAuthenticated && !props.started) {
      dispatch(AuthProvider.fetchSession(getAccessTokenSilently));
    }
  }, [isLoading, isAuthenticated, dispatch, getAccessTokenSilently, loginWithRedirect, props.started, props.loading, props.success]);

  if (!isAuthenticated || !props.success) {
    return (
      <div className={classes.status}>
        <CircularProgress size={120} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar variant="dense" className={classes.appBarToolbar}>
          <Typography
            variant="h6"
            color="inherit"
            className={classes.appBarTitle}
          >
            Sniper AI
          </Typography>

          <Button
            size="small"
            variant="outlined"
            color="inherit"
            className={classes.appBarButton}
            startIcon={<GiftIcon />}
            href="/prizes.pdf"
            target="_blank"
          >
            Premios
          </Button>

          <Button
            size="small"
            variant="outlined"
            color="inherit"
            className={classes.appBarButton}
            startIcon={<HelpIcon />}
            href="/help.pdf"
            target="_blank"
          >
            Cómo fotografiar
          </Button>

          <Avatar
            src={user!.picture}
            className={classes.appBarAvatar}
            onClick={() => setOpen(true)}
          />

          <Popover
            open={isOpen}
            onClose={() => setOpen(false)}
            anchorReference="none"
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            classes={{paper: classes.popoverPaper}}
          >
            <List component="nav">
            <ListItem>
                <ListItemText primary="Mis puntos" />
                <ListItemIcon className={classes.popoverMenuIcon}>
                  <Chip size="small" label={props.score || 0}/>  
                </ListItemIcon>
              </ListItem>
              <ListItem button={true} onClick={() => logout()}>
                <ListItemText primary="Cerrar sesión" />
                <ListItemIcon className={classes.popoverMenuIcon}>
                  <LogoutIcon />
                </ListItemIcon>
              </ListItem>
            </List>
          </Popover>
        </Toolbar>
      </AppBar>

      {props.token ? props.children : <Register />}
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  started: state.auth.started,
  loading: state.auth.loading,
  success: state.auth.success,
  token: state.auth.token,
  score: state.auth.score
});

export default connect(mapStateToProps, null)(AuthWrapper);