import 'moment/locale/es';
import React from 'react';
import moment from 'moment';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";
import { MuiThemeProvider, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import orange from '@material-ui/core/colors/orange';

import createStore from './utils/createStore';
import Snackbar from './components/Snackbar';

import AuthWrapper from './views/Auth/Wrapper';
import Editor from './views/Editor';

moment.locale('es');

const theme = unstable_createMuiStrictModeTheme({
  palette: {
    primary: orange
  }
});

const App: React.FC = () => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      redirectUri={window.location.origin}
    >
      <Provider store={createStore()}>
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <CssBaseline />
              <Snackbar />
              <AuthWrapper>
                <Switch>
                  <Route path="/" exact component={Editor} />
                </Switch>
              </AuthWrapper>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </Provider>
    </Auth0Provider>
  );
}

export default App;
