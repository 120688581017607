import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MUIDrawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';

import SelectIcon from '@material-ui/icons/MyLocation';
import DeleteIcon from '@material-ui/icons/Delete';

import { DRAWER_WIDTH, APPBAR_HEIGHT } from '.';
import { Sample } from '../../models/Sample';
import { Label } from '../../models/Editor';

interface Props {
  sample?: Sample;
  labels: Label[];
  onSelectLabel: (index: number) => void;
  onUpdateLabel: (index: number, label: Label) => void;
  onDeleteLabel: (index: number) => void;
  onSend: () => void;
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    paddingTop: APPBAR_HEIGHT,
    width: DRAWER_WIDTH,
    overflow: 'visible',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column'
  },
  drawerTitle: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    fontSize: 18,
    fontWeight: 400,
    fontFamily: 'Saira',
    backgroundColor: '#FCFCFC',
  },
  drawerContentTitle: {
    fontFamily: 'Saira',
    fontWeight: 500
  },
  labelContainer: {
    flex: 1,
    overflowY: 'scroll'
  },
  labelRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  labelRowSelected: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff4e5',
  },
  labelControl: {
    flex: 1,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  labelButton: {
    flex: 0,
    flexBasis: theme.spacing(5),
    padding: theme.spacing(1),
    marginLeft: theme.spacing(.25),
    marginRight: theme.spacing(.25),
  },
  sendButton: {
    margin: theme.spacing(1),
    color: '#FFF',
  }
}));

const Drawer: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <MUIDrawer
      anchor="right"
      variant="permanent"
      className={classes.drawer}
      classes={{paper: classes.drawerPaper}}
    >
      <Typography className={classes.drawerTitle}>
        Muestra
      </Typography>
      <Divider />
      <div>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell align="left" className={classes.drawerContentTitle}>ID</TableCell>
              <TableCell align="right">{props.sample ? props.sample.id : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" className={classes.drawerContentTitle}>DATASET</TableCell>
              <TableCell align="right">
                {props.sample ? props.sample.datasets.filter(dataset => dataset.type === 'crop').map(dataset =>
                  <Chip key={dataset.id} size="small" label={dataset.name} />  
                ) : ''}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <Typography className={classes.drawerTitle}>
        Etiquetas
      </Typography>
      <Divider />
      <div className={classes.labelContainer}>
        {props.labels.map((label, index) =>
          <div
            key={index}
            className={label.selected ? classes.labelRowSelected : classes.labelRow}
          >
            <IconButton
              size="small"
              onClick={() => props.onSelectLabel(index)}
              className={classes.labelButton}
            >
              <SelectIcon fontSize="inherit" />
            </IconButton>
            <FormControl
              key={index}
              fullWidth={true}
              variant="outlined"
              size="small"
              className={classes.labelControl}
            >
              <InputLabel>Tipo</InputLabel>
              <Select
                label="Tipo"
                value={label.type ? label.type : ''}
                onChange={e => props.onUpdateLabel(index, {...label, type: e.target.value as any})}
              >
                <MenuItem value=""><em>Ninguno</em></MenuItem>
                <MenuItem value="weed">Maleza</MenuItem>
                <MenuItem value="crop">Cultivo</MenuItem>
              </Select>
            </FormControl>
            <IconButton
              size="small"
              onClick={() => props.onDeleteLabel(index)}
              className={classes.labelButton}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </div>
        )}
      </div>
      <Divider />
      <Button
        variant="contained"
        color="primary"
        className={classes.sendButton}
        onClick={props.onSend}
        disabled={props.labels.length === 0 || !props.sample}
      >
        Enviar muestra
      </Button>
    </MUIDrawer>
  );
}

export default Drawer;
