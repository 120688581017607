import React from 'react';
import { Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { AppState } from '../reducers';
import { SnackbarAction } from '../reducers/Snackbar';
import { AlertSeverity } from '../models/Snackbar';

interface Props {
  message?: string;
  severity?: AlertSeverity;
  show?: boolean;
}

const SnackbarComponent: React.FC<Props> = (props: Props) => {
  
  const dispatch = useDispatch<Dispatch<SnackbarAction>>();

  const clearAlert = () => dispatch({ type: 'CLEAR_ALERT' });

  return (
    <Snackbar
      open={!!props.show}
      onClose={clearAlert}
      autoHideDuration={5000}
      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
    >
      <Alert onClose={clearAlert} severity={props.severity}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}

const mapStateToProps = (state: AppState) => ({
  message: state.snackbar.message,
  severity: state.snackbar.severity,
  show: state.snackbar.show
});

export default connect(mapStateToProps, null)(SnackbarComponent);
