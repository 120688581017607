import { Dispatch } from 'redux';
import { AppState } from '../reducers';
import { DatasetAction } from '../reducers/Dataset';
import { Dataset } from '../models/Sample';

type AutocompletePrediction = google.maps.places.AutocompletePrediction;

export interface DatasetProvider {
  fetchDatasets: () => void;
  createLocationDataset: (prediction: AutocompletePrediction) => void;
}

const createLocationDataset = (prediction: AutocompletePrediction) => {

  return async (dispatch: Dispatch<DatasetAction>, getState: () => AppState) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}/datasets/location/${prediction.place_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const body = await response.json();

    if (response.status !== 200) return;

    dispatch({ type: 'LOCATION_ADDED', newLocation: body });

  }

};

const fetchDatasets = () => {

  return async (dispatch: Dispatch<DatasetAction>, getState: () => AppState) => {

    const cropTypes = await fetchDatasetsByType('crop');

    const locations = await fetchDatasetsByType('location');
    
    dispatch({ type: 'DATASET_LIST_FETCH', cropTypes, locations });

  }

}

const fetchDatasetsByType = async (type: string): Promise<Dataset[]> => {

  const response = await fetch(`${process.env.REACT_APP_API_URL}/datasets?type=${type}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const body = await response.json();

  if (response.status !== 200) return [];

  return body.datasets;

}

const datasetProvider: DatasetProvider = { createLocationDataset, fetchDatasets };

export default datasetProvider;