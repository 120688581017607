import { Dispatch } from 'redux';
import { AppState } from '../reducers';
import { LabelingAction } from '../reducers/Labeling';
import { Sample, Dataset } from '../models/Sample';
import SnackbarProvider from './Snackbar';

export interface LabelingProvider {
  getUnlabeledSample: (dataset?: Dataset) => void;
  updateSample: (sample: Sample, dataset?: Dataset) => void;
}

const getUnlabeledSample = (dataset?: Dataset) => {

  return async (dispatch: Dispatch<LabelingAction>, getState: () => AppState) => {

    dispatch({ type: 'SAMPLE_FETCHING' });

    try {

      const response = await fetch(`${process.env.REACT_APP_API_URL}/samples/unlabeled${dataset ? '?datasetId=' + dataset.id : ''}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${ getState().auth.token }`
        }
      });

      const body = await response.json();

      if (response.status !== 200) throw new Error(body.error);
      
      dispatch({ type: 'SAMPLE_FETCH', sample: body });

    } catch (error) {

      console.log(error.message);

      dispatch({ type: 'SAMPLE_FETCH_FAILED', error: error.message });

    }

  }

}

const updateSample = (sample: Sample, dataset?: Dataset) => {

  return async (dispatch: Dispatch<LabelingAction>, getState: () => AppState) => {

    dispatch({ type: 'SAMPLE_UPDATING' });

    try {

      const response = await fetch(`${process.env.REACT_APP_API_URL}/samples/${sample.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${ getState().auth.token }`
        },
        body: JSON.stringify(sample)
      });

      const body = await response.json();

      if (response.status !== 200) throw new Error(body.error);
      
      dispatch({ type: 'SAMPLE_UPDATED' });

      dispatch(SnackbarProvider.showSuccessAlert('Muestra enviada correctamente') as any);

      dispatch(getUnlabeledSample(dataset) as any);

    } catch (error) {

      console.log(error.message);

      dispatch({ type: 'SAMPLE_UPDATE_FAILED', error: error.message });

      dispatch(SnackbarProvider.showErrorAlert('Error al enviar, reintente') as any);

    }

  }

}

const labelingProvider: LabelingProvider = { getUnlabeledSample, updateSample };

export default labelingProvider;