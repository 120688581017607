import React, { useState, useEffect } from 'react';
import { Layer, Image } from 'react-konva';
import { Box } from '../../models/Editor';
import { STAGE_WIDTH, STAGE_HEIGHT, BORDER_SIZE } from '.';

interface Props {
  url: string;
  onLoaded: (box: Box) => void;
}

const ImageLayer: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [image, setImage] = useState<HTMLImageElement|null>(null);
  const [imageBox, setImageBox] = useState<Box|null>(null);

  useEffect(() => {
    if (!loading && !image) {
      setLoading(true);
      const tmpImage = new window.Image();
      tmpImage.src = props.url;
      tmpImage.onload = () => {
        let x = 0, y = 0, width = 0, height = 0;
        if (STAGE_WIDTH / tmpImage.width < STAGE_HEIGHT / tmpImage.height) {
          width = STAGE_WIDTH - BORDER_SIZE;
          height = STAGE_WIDTH / tmpImage.width * tmpImage.height - BORDER_SIZE;
          x = BORDER_SIZE / 2;
          y = (STAGE_HEIGHT - STAGE_WIDTH / tmpImage.width * tmpImage.height + BORDER_SIZE) / 2;
        } else {
          width = STAGE_HEIGHT / tmpImage.height * tmpImage.width - BORDER_SIZE;
          height = STAGE_HEIGHT - BORDER_SIZE;
          x = (STAGE_WIDTH - STAGE_HEIGHT / tmpImage.height * tmpImage.width + BORDER_SIZE) / 2;
          y = BORDER_SIZE / 2;
        }
        setImageBox({x, y, width, height});
        setImage(tmpImage);
        setLoading(false);
        props.onLoaded({x, y, width, height});
      };
    }
  }, [loading, image, props]);

  return (
    <Layer>
      {image !== null &&
        <Image
          id="image"
          image={image}
          {...imageBox}
        />
      }
    </Layer>
  );
}

export default ImageLayer;
