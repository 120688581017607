import { Dispatch } from 'redux';
import { AppState } from '../reducers';
import { SnackbarAction } from '../reducers/Snackbar';

export interface SnackbarProvider {
  showSuccessAlert: (message: string) => void;
  showErrorAlert: (message: string) => void;
}

const showSuccessAlert = (message: string) =>
  async (dispatch: Dispatch<SnackbarAction>, getState: () => AppState) =>
    dispatch({ type: 'SHOW_ALERT', severity: 'success', message });

const showErrorAlert = (message: string) =>
  async (dispatch: Dispatch<SnackbarAction>, getState: () => AppState) =>
    dispatch({ type: 'SHOW_ALERT', severity: 'error', message });

const snackbarProvider: SnackbarProvider = { showSuccessAlert, showErrorAlert };

export default snackbarProvider;